<template>
  <b-card v-if="getUser.role.slug === 'super-administrateur'">
    <b-tabs>
      <b-tab
        active
        title="Liste des administrateurs"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Search -->
            <b-col
              offset-lcalg="6"
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Rechercher..."
                />
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refInvoiceListTable"
          :items="tableItems"
          responsive
          :busy="isLoading"
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Liste vide"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >
          <template #table-busy>
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle" /> &nbsp;
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- Column: Invoice Status -->
          <template #cell(role)="data">
            <b-badge
              pill
              :variant="`light-${resolveAdminRoleVariant(data.item.role.id)}`"
              class="text-capitalize font-small-3"
            >
              {{ data.item.role.name }}
            </b-badge>
          </template>

          <!-- Column: Client -->
          <template #cell(profile_image)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  rounded
                  :text="
                    avatarText(
                      `${data.item.first_name} ` + ` ${data.item.last_name}`
                    )
                  "
                  size="42"
                  :src="data.item.profile_image"
                />
              </template>
            </b-media>
          </template>

          <!-- Column: Issued Date -->
          <template #cell(phone_number)="data">
            <span class="text-nowrap font-small-3">
              {{ data.item.phone_number }}
            </span>
          </template>
          <!-- Column: Issued Date -->
          <template #cell(first_name)="data">
            <span class="text-nowrap font-small-3">
              {{ data.item.first_name }} {{ data.item.last_name }}
            </span>
          </template>

          <template #cell(email)="data">
            <span class="text-nowrap text-info font-small-3">
              {{ data.item.email }}
            </span>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <!-- Dropdown -->
              <b-dropdown
                variant="link"
                toggle-class="p-0"
                no-caret
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item v-if="data.item.role_id !== 3">
                  <feather-icon
                    variant="danger"
                    icon="TrashIcon"
                  />
                  <span
                    class="align-middle ml-50"
                    @click="applyDeleteUserAction(data.item.id)"
                  >Supprimer</span>
                </b-dropdown-item>

                <b-dropdown-item v-if="data.item.role_id !== 3" v-b-modal.modal-change-role>
                  <feather-icon
                    variant="success"
                    icon="Edit3Icon"
                  />
                  <span
                    class="align-middle ml-50"
                    @click="applyChangeUserRoleAction(data.item.id)"
                  >Changer le rôle</span>
                </b-dropdown-item>

                <b-dropdown-item v-if="data.item.role_id == 3">
                  <feather-icon
                    variant="danger"
                    icon="TrashIcon"
                  />
                  <span
                    class="align-middle ml-50"
                    @click="applyDeleteUserAction(data.item.id)"
                  >Quitter le dashboard</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span
                class="text-muted"
              >Affichage de {{ dataMeta.from }} à {{ dataMeta.to }} sur
                {{ dataMeta.of }} lignes</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-tab>
      
      
      <b-tab title="Invitation en attente">
        <pending-invitation />
      </b-tab>
    </b-tabs>
    <b-modal
        id="modal-change-role"
        cancel-variant="outline-secondary"
        hide-footer
        centered
        no-close-on-backdrop
        no-close-on-esc
        title="Changer le rôle d'un membre"
      >
        <validation-observer
          #default="{}"
          ref="changeRoleForm"
        >
          <b-form
            autocomplete="off"
            @submit.prevent="applyChangeRoleAdminAction"
          >
            
            <b-form-group
              label="Role"
              label-for="role"
            >
              <validation-provider
                #default="{ errors }"
                name="role"
                rules="required"
              >
                <b-form-select
                  id="role"
                  v-model="role_id"
                  :options="rolesOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-row>
              <b-col cols="12">
                <div
                  class="d-flex align-items-center justify-content-end"
                >
                  <b-button
                    :disabled="isRoleChangeLoading"
                    type="submiit"
                    variant="primary"
                    class="mr-2"
                  >
                    <div v-if="isRoleChangeLoading">
                      <span> Chargement ... </span>
                      <b-spinner small />
                    </div>
                    <span v-else>Envoyez</span>
                  </b-button>

                  <b-button
                    variant="outline-secondary"
                    @click="$bvModal.hide('modal-change-role')"
                  >
                    Fermer
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>
  </b-card>

  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BForm,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  // BLink,
  BBadge,
  BDropdown,
  // BAlert,
  BDropdownItem,
  BPagination,
  // BTooltip,
  BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
// import vSelect from 'vue-select'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import PendingInvitation from './PendingInvitations.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BFormGroup,
    BForm,
    BFormSelect,
    // BAlert,
    // BLink,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    // BAlert,
    BPagination,
    // BTooltip,
    BSpinner,
    // vSelect,
    ValidationProvider,
    ValidationObserver,
    PendingInvitation,
  },
  data() {
    return {
      required,
      adminId: "",
      role_id: "",
      rolesOptions: [],
      isRoleChangeLoading: false,
      tableItems: [],
      isLoading: true,
      statusOptions: [
        {
          label: 'Non validés',
          value: false,
        },

        {
          label: 'Validés',
          value: true,
        },
      ],
      tableColumns: [
        { key: 'profile_image', label: 'Photo', sortable: true },
        { key: 'first_name', label: 'Nom & Prénom', sortable: true },
        { key: 'phone_number', label: 'Numéro', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'role', label: 'Role', sortable: true },
        { key: 'actions' },
      ],
      perPage: 10,
      totalItems: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
      avatarText,
    }
  },
  computed: {
    ...mapGetters('users', ['getAdmins']),
    ...mapGetters('auth', ['getUser']),

    dataMeta() {
      const localItemsCount = this.$refs.refInvoiceListTable
        ? this.$refs.refInvoiceListTable.localItems.length
        : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalItems,
      }
    },
  },
  watch: {
    statusFilter() {
      this.refetchData()
    },
    currentPage() {
      this.refetchData()
    },
    perPage() {
      this.refetchData()
    },
    searchQuery() {
      this.refetchData()
    },
  },
  created() {
    this.applyGetRolesAction()
    console.log("admins", this.getAdmins)
    if (
      this.getUser.role.slug === 'super-administrateur'
    ) this.applyGetAdminsAction()
  },
  methods: {
    ...mapActions('users', ['getAdminsAction', 'deleteUserAction', "changeAdminRoleAction"]),
    ...mapActions('access', ['getRolesAction']),
    refetchData() {
      const params = {
        q: this.searchQuery,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        status: this.statusFilter,
      }
      const { items, total } = this.getData(params)
      this.tableItems = items
      this.total = total
      this.totalItems = this.total
    },
    resolveAdminRoleVariant: status => {
      if (status === true) return 'success'
      return 'warning'
    },

    sortCompare: key => (a, b) => {
      const fieldA = a[key]
      const fieldB = b[key]

      let comparison = 0
      if (fieldA > fieldB) {
        comparison = 1
      } else if (fieldA < fieldB) {
        comparison = -1
      }
      return comparison
    },
    paginateArray: (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage),
    applyChangeUserRoleAction(admin){
      this.adminId = admin
    },
    applyGetRolesAction() {
      this.getRolesAction().then(response => {
        response.data.forEach(element => {
          if (element.name !== 'client') {
            this.rolesOptions.push({
              value: element.id,
              text: element.name,
            })
          }
        })
      })
    },
    applyChangeRoleAdminAction() {
      this.$refs.changeRoleForm.validate().then(success => {
        if (success) {
          this.isRoleChangeLoading = true

          this.changeAdminRoleAction({userId: this.adminId, roleId: this.role_id})
            .then(response => {
              this.applyGetAdminsAction()
              this.isRoleChangeLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )

              this.$bvModal.hide('modal-change-role')
             
                this.role_id = ''
                
            })
            .catch(error => {
              this.isRoleChangeLoading = false
              console.log(error)
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            })
        }
      })
    },
    applyGetAdminsAction() {
      this.isLoading = true
      this.getAdminsAction().then(response => {
        console.log(response)
        const params = {
          q: this.searchQuery,
          perPage: this.perPage,
          page: this.currentPage,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
          status: this.statusFilter,
        }
        const { items, total } = this.getData(params)
        this.tableItems = items
        this.total = total
        this.totalItems = this.total
        this.isLoading = false
      })
    },
    applyDeleteUserAction(id) {
      // eslint-disable-next-line no-alert
      this.$swal
      .fire({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer cet admin?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteUserAction(id)
          .then((response) => {})
              .catch((error) => {
                this.$swal.showValidationMessage(`${error.response.data.message}`);
              })
          // eslint-disable-next-line no-return-await
          return await this.getAdminsAction()
          
        },
      })
      
        .then(response => {
          console.log(response)
          if (response.value) {
            this.refetchData()
            this.$toast(
              {
                // eslint-disable-next-line no-undef
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'pro supprimé avec succès',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              // eslint-disable-next-line no-undef
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    getData(params) {
      const data = this.getAdmins
      // eslint-disable-next-line object-curly-newline
      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'id',
        sortDesc = false,
        // eslint-disable-next-line no-unused-vars
        status = null,
      } = params
      /* eslint-enable */

      const queryLowered = q.toLowerCase()
      const filteredData = data.filter(
        item =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          item.first_name.toLowerCase().includes(queryLowered) ||
          item.last_name.toLowerCase().includes(queryLowered) ||
          item.email.toLowerCase().includes(queryLowered),
      )
      /* eslint-enable  */

      const sortedData = filteredData.sort(this.sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      return {
        items: this.paginateArray(sortedData, perPage, page),
        total: filteredData.length,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

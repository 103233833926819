<template>
  <div class="div">
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-button
            v-b-modal.modal-invitation
            variant="primary"
          >
            <span class="text-nowrap">Invitez un admin</span>
            <b-modal
              id="modal-invitation"
              cancel-variant="outline-secondary"
              hide-footer
              centered
              no-close-on-backdrop
              no-close-on-esc
              title="Inviter un membre"
            >
              <validation-observer
                #default="{}"
                ref="invitationForm"
              >
                <b-form
                  autocomplete="off"
                  @submit.prevent="applyInviteAdminAction"
                >
                  <b-form-group
                    label-for="email"
                    label="Adresse email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="payload.email"
                        name="login-email"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Entrez  l'adresse email du membre"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  
                  <b-form-group
                    label="Role"
                    label-for="role"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="role"
                      rules="required"
                    >
                      <b-form-select
                        id="role"
                        v-model="payload.role_id"
                        :options="rolesOptions"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-row>
                    <b-col cols="12">
                      <div
                        class="d-flex align-items-center justify-content-end"
                      >
                        <b-button
                          :disabled="isInvitationLoading"
                          type="submiit"
                          variant="primary"
                          class="mr-2"
                        >
                          <div v-if="isInvitationLoading">
                            <span> Chargement ... </span>
                            <b-spinner small />
                          </div>
                          <span v-else>Envoyez</span>
                        </b-button>

                        <b-button
                          variant="outline-secondary"
                          @click="$bvModal.hide('modal-invitation')"
                        >
                          Fermer
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-modal>
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Rechercher..."
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refInvoiceListTable"
      :items="tableItems"
      responsive
      :busy="isLoading"
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Liste vide"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <template #table-busy>
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle" /> &nbsp;
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- Column: Invoice Status -->
      <template #cell(role)="data">
        <b-badge
          pill
          :variant="`light-${resolveAdminRoleVariant(data.item.role.id)}`"
          class="text-capitalize font-small-3"
        >
          {{ data.item.role.name }}
        </b-badge>
      </template>

      <template #cell(email)="data">
        <span class="text-nowrap text-info font-small-3">
          {{ data.item.email }}
        </span>
      </template>

      <template #cell(invited_by)="data">
        <span class="text-nowrap text-success font-small-3">
          {{ data.item.invited_by.first_name }}
          {{ data.item.invited_by.last_name }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="applyDeleteInvitationAction(data.item.token)"
            >
              <feather-icon
                variant="danger"
                icon="TrashIcon"
              />
              <span class="align-middle ml-50">Supprimer</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          "
        >
          <span
            class="text-muted"
          >Affichage de {{ dataMeta.from }} à {{ dataMeta.to }} sur
            {{ dataMeta.of }} lignes</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-end
          "
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  // BTab,
  // BTabs,
  // BCard,
  BRow,
  // BFormInvalidFeedback,
  BCol,
  BFormInput,
  BButton,
  BTable,
  // BMedia,
  // BAvatar,
  // BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  // BTooltip,
  BFormGroup,
  BSpinner,
  BFormSelect,
  BForm,
  // BAlert,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
// import vSelect from 'vue-select'
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BTab,
    // BTabs,
    // BFormInvalidFeedback,

    // BCard,
    BFormGroup,
    // BAlert,
    // BLink,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    BTable,
    // BMedia,
    // BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    // BTooltip,
    BSpinner,
    // vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      tableItems: [],
      payload: {
        email: '',
        role_id: '',
      },
      isLoading: false,
      isInvitationLoading: false,
      required,
      email,
      rolesOptions: [],
      tableColumns: [
        { key: 'email', label: 'Email', sortable: true },
        { key: 'role', label: 'Role', sortable: true },
        { key: 'invited_by', label: 'Invité par', sortable: true },
        { key: 'actions' },
      ],
      perPage: 10,
      totalItems: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
      avatarText,
    }
  },
  computed: {
    ...mapGetters('users', ['getPendingInvitations']),
    dataMeta() {
      const localItemsCount = this.$refs.refInvoiceListTable
        ? this.$refs.refInvoiceListTable.localItems.length
        : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalItems,
      }
    },
  },
  watch: {
    statusFilter() {
      this.refetchData()
    },
    currentPage() {
      this.refetchData()
    },
    perPage() {
      this.refetchData()
    },
    searchQuery() {
      this.refetchData()
    },
  },
  created() {
    this.applyGetPendingInvitationsAction()
    this.applyGetRolesAction()
  },
  methods: {
    ...mapActions('users', [
      'getPendingInvitationsAction',
      'inviteAdminAction',
      'deleteInvitationAction',
    ]),
    ...mapActions('access', ['getRolesAction']),

    refetchData() {
      const params = {
        q: this.searchQuery,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        status: this.statusFilter,
      }
      const { items, total } = this.getData(params)
      this.tableItems = items
      this.total = total
      this.totalItems = this.total
    },
    resolveAdminRoleVariant: status => {
      if (status === true) return 'success'
      return 'warning'
    },

    sortCompare: key => (a, b) => {
      const fieldA = a[key]
      const fieldB = b[key]

      let comparison = 0
      if (fieldA > fieldB) {
        comparison = 1
      } else if (fieldA < fieldB) {
        comparison = -1
      }
      return comparison
    },
    paginateArray: (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage),

    applyGetPendingInvitationsAction() {
      this.isLoading = true
      this.getPendingInvitationsAction().then(() => {
        const params = {
          q: this.searchQuery,
          perPage: this.perPage,
          page: this.currentPage,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
          status: this.statusFilter,
        }
        const { items, total } = this.getData(params)
        this.tableItems = items
        this.total = total
        this.totalItems = this.total
        this.isLoading = false
      })
    },
    applyGetRolesAction() {
      this.getRolesAction().then(response => {
        response.data.forEach(element => {
          if (element.name !== 'client') {
            this.rolesOptions.push({
              value: element.id,
              text: element.name,
            })
          }
        })
      })
    },
    applyInviteAdminAction() {
      this.$refs.invitationForm.validate().then(success => {
        if (success) {
          this.isInvitationLoading = true

          this.inviteAdminAction(this.payload)
            .then(response => {
              this.applyGetPendingInvitationsAction()
              this.isInvitationLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )

              this.$bvModal.hide('modal-invitation')
              this.payload = {
                role_id: '',
                email: '',
              }
            })
            .catch(error => {
              this.isInvitationLoading = false
              console.log(error)
              /* this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              ) */
            })
        }
      })
    },
    applyDeleteInvitationAction(token) {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer cette invitation?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.deleteInvitationAction(token),
      })
        .then(response => {
          console.log(response)
          if (response.value && response.value.status === 200) {
            this.applyGetPendingInvitationsAction()
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: response.value.data.message,
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
           ;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    getData(params) {
      const data = this.getPendingInvitations
      // eslint-disable-next-line object-curly-newline
      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'id',
        sortDesc = false,
        // eslint-disable-next-line no-unused-vars
        status = null,
      } = params
      /* eslint-enable */

      const queryLowered = q.toLowerCase()
      const filteredData = data.filter(item =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */

        item.email.toLowerCase().includes(queryLowered))
      /* eslint-enable  */

      const sortedData = filteredData.sort(this.sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      return {
        items: this.paginateArray(sortedData, perPage, page),
        total: filteredData.length,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
